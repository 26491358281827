<template>
  <svg :class="svgClass" aria-hidden="true" @click="svgClick">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['click']);

const props = defineProps({
  iconClass: {
    type: String,
    require: true,
  },
  className: {
    type: String,
    default: '',
  },
});

const iconName = computed(() => `#icon-${props.iconClass}`);
const svgClass = computed(() => (props.className === '' ? 'svg-icon' : `svg-icon ${props.className}`));

const svgClick = () => {
  emit('click');
};
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
