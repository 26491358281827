import axios from 'axios';
import { loalStorageGet } from '@/utils/common.js';
import responseStatus from './status';
import router from '@/router';
// 导入vue-router实例
class HTTP {
  constructor({ options, defaultHeaders }) {
    this.REQUEST_GET = 'GET';
    this.REQUEST_POST = 'POST';
    this.HTTPSTATUS = responseStatus;
    this.defaultHeaders = defaultHeaders || {
      'Content-Type': 'application/json;charset=UTF-8',
    };
    this.options = options || {
      baseApi: '',
      timeout: 5000, // 超时响应，默认5000秒
    };
    this.service = null;

    this.init();
  }

  /**
   * @description 请求，参数与axios一致
   * @see https://github.com/axios/axios#request-config
   */
  request(config) {
    return this.service(config);
  }

  /**
   * @description 快捷get请求
   * @see https://github.com/axios/axios#axios-api
   */
  get(config) {
    return this.request({ method: this.REQUEST_GET, ...config });
  }

  /**
   * @description 快捷post请求
   * @param {url} Y 请求地址
   * @param {data} N 请求数据
   * @see https://github.com/axios/axios#axios-api
   */
  post(config) {
    return this.request({ method: this.REQUEST_POST, ...config });
  }

  /**
   * 初始化axios
   */
  init() {
    axios.defaults.baseURL = this.options.baseApi;
    // axios实例，拦截请求&响应
    this.service = axios.create({
      timeout: this.options.timeout,
      headers: this.defaultHeaders,
    });
    this.service.interceptors.request.use(
      (config) => {
        if (!config.url) {
          config.data.token = loalStorageGet('token') || '';
        }
        if (loalStorageGet('OAuth2AccessToken')) {
          config.headers.Authorization = `Bearer ${loalStorageGet('OAuth2AccessToken')}`;
        }
        config.baseURL = config.url ? config.baseURL : `${config.baseURL}?${config.data.func}`;
        return config;
      },
      (err) => Promise.reject(err),
    );
    this.service.interceptors.response.use((response) => {
      // 文件流
      // 普通字符类型
      try {
        if (response.status === 200) {
          if (response.data?.debug == 120) {
            setTimeout(() => {
              router.replace({ name: 'home' }).then(() => {
                window.location.reload();
              });
            }, 3000);
          }
          return response.data;
        }
      } catch (err) {
        throw new Error(err);
      }
    });
  }
}

export default HTTP;
